<script>
import $ from "jquery"
import Gen from '@/libs/Gen'

export default {
  data() {
    return {
    }
  },
  mounted(){
  },
  methods: {
    onChange(){
      const file = this.$refs['input'].files[0]
      console.log(file)
    },
    onUpload(){
      let formData = new FormData()
      let query = {
        token: Gen.getCookie("u_auth"),
      }
      this.uploading = true
      $.ajax({
        url: this.apiUrl + "/api/app/ajax/upload_dropbox?"+Gen.objectToQuery(query),
        headers:Gen.apiHeader(),
        type: "POST",
        data: formData,
        enctype: 'multipart/form-data',
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
      })
    }
  },
}
</script>

<style lang="scss" scoped> 
</style>

<template>
 <label class="file">
  <input type="file" id="file" aria-label="File browser example">
  <span class="file-custom"></span>
</label>
</template>